var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5",class:{'text-center':_vm.align==='center','text-left-resp':_vm.align==='left'}},[_c('h3',{staticClass:"text-primary",class:{'bottom-tag-center':_vm.bottomTagAlign === 'center',
         'bottom-tag-left':_vm.bottomTagAlign === 'left',
         'show':_vm.showBottomLine,
         'mb-3': _vm.info !=='',
         'text-uppercase': _vm.textUppercase,
         'fs-xlg font-manrope-bold': _vm.size === 'xlg',
         'fs-lg font-manrope-extra-bold': _vm.size === 'lg',
         'fs-md font-manrope-extra-bold': _vm.size === 'md'}},[_vm._v(" "+_vm._s(_vm.title))]),(_vm.title !== '')?_c('div',[_vm._t("custom")],2):_vm._e(),_c('p',{staticClass:"font-manrope-regular mt-5 text-4 text-center px-lg-8 lh-2"},[_vm._v(_vm._s(_vm.info))])])}
var staticRenderFns = []

export { render, staticRenderFns }