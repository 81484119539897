const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/';
} else {
    baseUrl = '/';
}

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        status: baseUrl + 'auth/training-menu/'
    },
    training: {
        details: baseUrl + 'auth/training/details/',
        enquiry: baseUrl + 'event/enquiry/create/'
    },
    getCaptcha: baseUrl + 'auth/get-captcha/',
    career: {
        list: baseUrl + 'auth/career/front-end/list/',
        applyNow: baseUrl + 'auth/career-application/add-edit/'
    },
    partner: {
        list: baseUrl + 'auth/partner/front-end/list/',
        partnersPage: baseUrl + 'event/website/partners-page/'
    },
    registration: baseUrl + 'auth/register/event-training/add-edit/',
    contactUs: baseUrl + 'auth/register/front-end/send-mail/',
    contactUsPage: baseUrl + 'event/website/contact-us-page/',
    paymentInitials: baseUrl + 'transaction/payment/initialization/',
    regInitials: baseUrl + 'auth/register/front-end/form-initial/',
    scheduleOptions: baseUrl + 'auth/training/schedule/options/',
    addressList: baseUrl + 'auth/address/front-end/list/',
    website: {
        homePage: baseUrl + 'event/website/home-page/',
        aboutUsPage: baseUrl + 'event/website/about-us-page/',
        serviceDetails: baseUrl + 'event/website/service-details/'
    },
    blog: {
        blogPage: baseUrl + 'event/website/blog-page/',
        blogInnerPage: baseUrl + 'event/website/blog-details/',
        blogViewAll: baseUrl + 'event/website/blog-list/'
    },
    gallery: {
        list: baseUrl + 'event/website/gallery-list-page/',
        detailsPage: baseUrl + 'event/website/gallery-details-page/',
        videoGallery: baseUrl + 'event/website/video-list-page/'
    },
    solutions: baseUrl + 'event/website/solutions-page/'
};
