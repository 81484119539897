<template>
    <div class="mb-5" :class="{'text-center':align==='center','text-left-resp':align==='left'}">
        <h3 class="text-primary"
            :class="{'bottom-tag-center':bottomTagAlign === 'center',
             'bottom-tag-left':bottomTagAlign === 'left',
             'show':showBottomLine,
             'mb-3': info !=='',
             'text-uppercase': textUppercase,
             'fs-xlg font-manrope-bold': size === 'xlg',
             'fs-lg font-manrope-extra-bold': size === 'lg',
             'fs-md font-manrope-extra-bold': size === 'md'}">
            {{ title }}</h3>
        <div v-if="title !== ''">
            <slot name="custom"></slot>
        </div>
        <p class="font-manrope-regular mt-5 text-4 text-center px-lg-8 lh-2">{{ info }}</p>
    </div>
</template>
<script> export default {
    name: 'SectionHeading',
    props: {
        title: {
            type: String
        },
        info: {
            type: String,
            default: ''
        },
        showBottomLine: {
            type: Boolean,
            default: true
        },
        align: {
            type: String,
            default:
                'center'
        },
        size: {
            type: String,
            default:
                'md'
        },
        bottomTagAlign:
            {
                type: String,
                default:
                    'center'
            },
        textUppercase: {
            type: Boolean,
            default:
                false
        }
    }
}
;
</script>
<style scoped lang="scss">
    .bottom-tag-center {
        position: relative;

        &.show {
            &::after {
                content: '';
                position: absolute;
                //width: 5%;
                width: 4.5rem;
                left: 50%;
                transform: translateX(-50%);
                height: 3px;
                bottom: -1.7rem;
                //background-color: rgba(0,0,0,0.65);
                //background-color: var(--color-primary);
                background: url("../../assets/web/lines/heading-underline.svg");
                //margin-top: 1rem;
            }
        }
    }

    .bottom-tag-left {
        position: relative;
        display: inline;

        &.show {
            &::after {
                content: '';
                position: absolute;
                //width: 5%;
                width: 4.5rem;
                //transform: translateX(-50%);
                bottom: -1rem;
                left: 0;
                height: 2px;
                //background-color: rgba(0,0,0,0.65);
                //background-color: var(--color-primary);
                background: url("../../assets/web/lines/heading-underline.svg");

                //margin-top: 1rem;
            }
        }
    }

    .fs-md {
        font-size: 1.4rem;
        @media (min-width: 992px) {
            font-size: 1.5rem;
        }
    }

    .fs-lg {
        font-size: 1.7rem;
        //font-size: 2.5rem;
    }

    .fs-xlg {
        font-size: 1.4rem;
        @media (min-width: 992px) {
            font-size: 1.87rem;
        }
    }
</style>
