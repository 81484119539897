import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Career = () => import(/* webpackChunkName: "home" */'../views/Career');
const Partners = () => import(/* webpackChunkName: "home" */'../views/Partners');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.statusGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/career/',
            name: 'Career',
            component: Career
        },
        {
            path: '/partners/',
            name: 'Partners',
            component: Partners
        },
        {

            path: '/Career-InnerPage/:id/:title',
            name: 'CareerInnerPage',
            component: () => import(/* webpackChunkName: "home" */'../views/CareerInnerPage/CareerInnerPage')

        },
        {
            path: '/blog-article/',
            name: 'BlogsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/blogs/RecentBlogs')
        },
        {
            path: '/all-blogs/',
            name: 'AllBlogs',
            component: () => import(/* webpackChunkName: "home" */'../views/blogs/AllBlogsListing')
        },
        {
            path: '/blog-article-article/:id/details/',
            name: 'BlogArticlePage',
            component: () => import(/* webpackChunkName: "home" */'../views/blogs/BlogArticlePage')
        },
        {
            path: '/services/:id/',
            name: 'TheServices',
            component: () => import(/* webpackChunkName: "home" */'../views/services/TheServices')
        },
        {
            path: '/contact/',
            name: 'TheServices',
            component: () => import(/* webpackChunkName: "home" */'../views/ContactPage')
        },
        {
            path: '/Components/',
            name: 'TheComponents',
            component: () => import(/* webpackChunkName: "home" */'../views/dev/ComponentsPage')
        },
        {
            path: '/solutions/',
            name: 'SolutionsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/SolutionsPage')

        },
        {
            path: '/course/:training/:id/',
            name: 'TrainingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/TrainingPage')

        },
        // Gallery
        {
            path: '/photo-gallery/',
            name: 'PhotoGalleryPage',
            component: () => import(/* webpackChunkName: "home" */'../views/gallery/PhotoGalleryPage')

        },
        {
            path: '/gallery-inner-page/:title/:id',
            name: 'Gallery-InnerPage',
            component: () => import(/* webpackChunkName: "home" */'../views/GalleryInnerPage')

        },
        {
            path: '/video-gallery/',
            name: 'VideoGalleryPage',
            component: () => import(/* webpackChunkName: "home" */'../views/gallery/VideoGalleryPage')

        },
        {
            path: '/privacy-policy/',
            name: 'PrivacyPolicyPage',
            component: () => import(/* webpackChunkName: "home" */'../views/PrivacyPolicyPage')

        }
    ]
};
